import { graphql, navigate } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import * as React from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const NotFoundPage = ({location}) => {
  const { i18n } = useI18next();
  React.useEffect(() => {
    if(
      location.pathname === '/careers/job/' || 
      location.pathname === '/careers/jobs/' || 
      location.pathname === '/careers/job' || 
      location.pathname === '/careers/jobs' ||
      location.pathname === '/careers/jobs-' ||
      location.pathname === '/careers/job-' ||
      location.pathname === '/careers/jobs-/' ||
      location.pathname === '/careers/job-/' ||
      location.pathname === '/careers' 
      ) {
      navigate('/careers/jobs/')
    }
  }, [location]);

  return (
    <Layout lang={i18n.language}>
      <Seo title="404: Not found" />
      <section className="section-error-page">
          <div className="error-page-tx-wrapper">
            <h1>404: Not Found</h1>
            <p>Nothing here, my friend..</p>
          </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
